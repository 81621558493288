import { Box, Fab } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line react/prop-types
const EditOptionsIcon = ({ params }) => {
    const dispatch = useDispatch();

    const handleSubmit = async () => {
        // eslint-disable-next-line react/prop-types
        dispatch({
            type: 'OPEN_EDIT_QUESTION_OPTIONS',
            payload: {
                open: true,
                // eslint-disable-next-line react/prop-types
                options: params.row.options,
                // eslint-disable-next-line react/prop-types
                questionId: params.row._id,
                // eslint-disable-next-line react/prop-types
                body: params.row.body
            }
        });
    };

    return (
        <Box
            sx={{
                m: 1,
                position: 'relative'
            }}
        >
            <Fab
                color="primary"
                sx={{
                    width: 40,
                    height: 40
                }}
                onClick={handleSubmit}
            >
                <Edit />
            </Fab>
        </Box>
    );
};

export default EditOptionsIcon;
