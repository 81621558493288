import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateChallengeGift } from '../../store/actions/challenges/question';

// eslint-disable-next-line react/prop-types
const UpdateGift = () => {
    const customization = useSelector((state) => state.customization);
    const { openChallengeGift, challengesMinPoints } = customization;
    const dispatch = useDispatch();
    const giftRef = useRef();
    const pointsRef = useRef();

    const handleClose = () => {
        dispatch({ type: 'OPEN_CHALLENGES_GIFT', payload: { ...openChallengeGift, open: false } });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        var gift = giftRef.current.value;
        var min_points = pointsRef.current.value;
        if (!gift) gift = openChallengeGift.gift;
        if (!min_points) min_points = challengesMinPoints;
        updateChallengeGift(openChallengeGift, gift, min_points, dispatch);
    };
    return (
        <Dialog open={openChallengeGift.open} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>
                Challenge Config
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                    onClick={handleClose}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent dividers>
                    <DialogContentText>Old Gift : {openChallengeGift.gift}</DialogContentText>
                    <DialogContentText>Required Points : {challengesMinPoints}</DialogContentText>
                    <TextField margin="normal" variant="standard" id="name" label="Gift" type="text" fullWidth inputRef={giftRef} />
                    <TextField
                        margin="normal"
                        variant="standard"
                        id="points"
                        label="Required Points"
                        type="number"
                        fullWidth
                        inputRef={pointsRef}
                    />
                </DialogContent>
                <DialogActions sx={{ px: '19px' }}>
                    <Button type="submit" variant="contained" endIcon={<Send />}>
                        Update
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default UpdateGift;
