import { Box, Fab } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line react/prop-types
const OpenDeleteQuestion = ({ params }) => {
    const dispatch = useDispatch();

    const handleSubmit = async () => {
        // eslint-disable-next-line react/prop-types
        const { id } = params;
        dispatch({
            type: 'OPEN_DELETE_QUESTION',
            payload: {
                open: true,
                id
            }
        });
    };

    return (
        <Box
            sx={{
                m: 1,
                position: 'relative'
            }}
        >
            <Fab
                color="white"
                sx={{
                    width: 40,
                    height: 40
                }}
                onClick={handleSubmit}
            >
                <DeleteForever style={{ color: 'red' }} />
            </Fab>
        </Box>
    );
};

export default OpenDeleteQuestion;
