// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const UPDATE_ADMINS = 'UPDATE_ADMINS';
export const UPDATE_ALERT = 'UPDATE_ALERT';
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const OPEN_ADD_ADMIN = 'OPEN_ADD_ADMIN';
export const CLOSE_ADD_ADMIN = 'CLOSE_ADD_ADMIN';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const UPDATE_ADD_CATEGORY = 'UPDATE_ADD_CATEGORY';
export const UPDATE_CATEGORY_IMAGE = 'UPDATE_CATEGORY_IMAGE';
export const UPDATE_LEVELS = 'UPDATE_LEVELS';
export const UPDATE_ADD_LEVEL = 'UPDATE_ADD_LEVEL';
export const UPDATE_LEVEL_IMAGE = 'UPDATE_LEVEL_IMAGE';
export const UPDATE_QUESTIONS = 'UPDATE_QUESTIONS';
export const UPDATE_ADD_QUESTION = 'UPDATE_ADD_QUESTION';
export const UPDATE_WORD_QUESTION = 'UPDATE_WORD_QUESTION';
export const UPDATE_QUESTIONS_WORDSEARCH = 'UPDATE_QUESTIONS_WORDSEARCH';
export const OPEN_EDIT_QUESTION_OPTIONS = 'OPEN_EDIT_QUESTION_OPTIONS';
export const OPEN_EDIT_QUESTION_ANSWER = 'OPEN_EDIT_QUESTION_ANSWER';
export const OPEN_EDIT_QUESTION_ANSWER_OPTIONS = 'OPEN_EDIT_QUESTION_ANSWER_OPTIONS';
export const UPDATE_RANDOM_QUESTIONS = 'UPDATE_RANDOM_QUESTIONS';
export const UPDATE_ADD_RANDOM_QUESTION = 'UPDATE_ADD_RANDOM_QUESTION';
export const OPEN_EDIT_RANDOM_QUESTION_OPTIONS = 'OPEN_EDIT_RANDOM_QUESTION_OPTIONS';
export const OPEN_EDIT_RANDOM_QUESTION_ANSWER = 'OPEN_EDIT_RANDOM_QUESTION_ANSWER';
export const OPEN_EDIT_RANDOM_QUESTION_ANSWER_OPTIONS = 'OPEN_EDIT_RANDOM_QUESTION_ANSWER_OPTIONS';
export const UPDATE_FEEDBACKS = 'UPDATE_FEEDBACKS';
export const UPDATE_WINNERS = 'UPDATE_WINNERS';
export const OPEN_DELETE_WINNER = 'OPEN_DELETE_WINNER';
export const UPDATE_QUESTIONS_CHALLENGE = 'UPDATE_QUESTIONS_CHALLENGE';
export const OPEN_CHECK_CHALLENGE = 'OPEN_CHECK_CHALLENGE';
export const GET_CHALLENGES_STATUS = 'GET_CHALLENGES_STATUS';
export const GET_CHALLENGES_GIFT = 'GET_CHALLENGES_GIFT';
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
export const OPEN_SEND_NOTIFICATION = 'OPEN_SEND_NOTIFICATION';
export const OPEN_CHALLENGES_GIFT = 'OPEN_CHALLENGES_GIFT';
export const GET_CHALLENGE_RANK = 'GET_CHALLENGE_RANK';
export const RATE_APP_STORE_LINKS = 'RATE_APP_STORE_LINKS';
export const ABOUT_US_STATIC_PAGE = 'ABOUT_US_STATIC_PAGE';
export const GET_REDEEM_REQUESTS = 'GET_REDEEM_REQUESTS';
export const OPEN_DELETE_REDEEM_REQUEST = 'OPEN_DELETE_REDEEM_REQUEST';
export const GET_CHALLENGES_MIN_POINTS = 'GET_CHALLENGES_MIN_POINTS';
export const OPEN_DELETE_QUESTION = 'OPEN_DELETE_QUESTION';
export const OPEN_CHANGE_PASSWORD = 'OPEN_CHANGE_PASSWORD';
export const OPEN_FORGOT_PASSWORD = 'OPEN_FORGOT_PASSWORD';
export const OPEN_DELETE_USER = 'OPEN_DELETE_USER';
export const OPEN_INCREMENT_USER_POINTS = 'OPEN_INCREMENT_USER_POINTS';
export const OPEN_DECREMENT_USER_POINTS = 'OPEN_DECREMENT_USER_POINTS';
