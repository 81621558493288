import { Button, Dialog, DialogActions, DialogTitle, IconButton, Typography } from '@mui/material';
import { Close, Delete } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { deleteQuestions, deleteWordSearchQuestion } from '../../store/actions/challenges/question';

// eslint-disable-next-line react/prop-types
const DeleteQuestion = () => {
    const customization = useSelector((state) => state.customization);
    const { deleteQuestion } = customization;
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch({ type: 'OPEN_DELETE_QUESTION', payload: { ...deleteQuestion, open: false } });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        deleteWordSearchQuestion(deleteQuestion, deleteQuestion.id, dispatch);
    };
    return (
        <Dialog open={deleteQuestion.open} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>
                <Typography>Are You sure you want to delete this question ?</Typography>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                    onClick={handleClose}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogActions sx={{ px: '19px' }}>
                    <Button type="submit" variant="contained" color="error" endIcon={<Delete />}>
                        Yes
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default DeleteQuestion;
