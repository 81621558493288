import administration from './administration';
import users from './users';
import mobileControl from './mobileControl';
import feedback from './feedback';
import staticPages from './staticPages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [administration, users, mobileControl, feedback, staticPages]
};

export default menuItems;
