// project imports
import fetchData from './utils/fetchData';
import { ENDPOINT_BASE_URL } from '../../constants';
import { RATE_APP_STORE_LINKS, START_LOADING, END_LOADING, UPDATE_ALERT, ABOUT_US_STATIC_PAGE } from '../actions';

const url = ENDPOINT_BASE_URL;

export const getRateApp = async (dispatch) => {
    dispatch({ type: START_LOADING });
    const result = await fetchData({ url: url + '/api/static-pages-handler/store-link/web', method: 'GET' }, dispatch);
    if (result) {
        dispatch({ type: RATE_APP_STORE_LINKS, payload: { googleLink: result.googleLink, appleLink: result.appleLink } });
    }
    dispatch({ type: END_LOADING });
};

export const updateLinks = async (updateStoreLinks, dispatch) => {
    dispatch({ type: START_LOADING });

    const { googleLink, appleLink } = updateStoreLinks;

    try {
        const result = await fetchData(
            {
                url: url + `/api/static-pages-handler/update-store-link`,
                method: 'PATCH',
                body: { googleLink, appleLink }
            },
            dispatch
        );

        getRateApp(dispatch);

        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'success',
                message: result
            }
        });
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};

export const getStaticPage = async (page, dispatch) => {
    dispatch({ type: START_LOADING });
    const result = await fetchData({ url: url + `/api/static-pages-handler/get-page/${page}`, method: 'GET' }, dispatch);
    if (page == 'about-us' && result) {
        dispatch({ type: ABOUT_US_STATIC_PAGE, payload: { aboutUsPage: result.aboutUsPage } });
    } else if (page == 'privacy-policy' && result) {
        dispatch({ type: ABOUT_US_STATIC_PAGE, payload: { privacyPolicyPage: result.privacyPolicyPage } });
    }
    dispatch({ type: END_LOADING });
};

export const updateStaticPage = async (updateStaticPage, dispatch) => {
    dispatch({ type: START_LOADING });

    const { page, content } = updateStaticPage;

    try {
        const result = await fetchData(
            {
                url: url + `/api/static-pages-handler/update-page/${page}`,
                method: 'PATCH',
                body: { content }
            },
            dispatch
        );

        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'success',
                message: result
            }
        });
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};
