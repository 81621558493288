import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from './AuthGuard';
import WordSearch from 'views/word-search';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// administration routing
const ManageAdmins = Loadable(lazy(() => import('views/administration/ManageAdmins')));

// users routing
const ManageUsers = Loadable(lazy(() => import('views/users/ManageUsers')));

// categories routing
const Categories = Loadable(lazy(() => import('views/categories/Categories')));
const Levels = Loadable(lazy(() => import('views/levels/Levels')));
const Questions = Loadable(lazy(() => import('views/questions/Questions')));
const RandomQuestions = Loadable(lazy(() => import('views/randomQuestion/RandomQuestions')));

// Feedback routing
const Feedback = Loadable(lazy(() => import('views/feedback/Feedback')));

// competition categories routing
const CCategories = Loadable(lazy(() => import('views/competitions/categories/CCategories')));
const CLevels = Loadable(lazy(() => import('views/competitions/levels/CLevels')));
const CQuestions = Loadable(lazy(() => import('views/competitions/questions/CQuestions')));
const Winners = Loadable(lazy(() => import('views/competitions/winners/Winners')));
const CRandomQuestions = Loadable(lazy(() => import('views/competitions/randomQuestion/CRandomQuestions')));

// challenge routing
const Challenges = Loadable(lazy(() => import('views/challenges/Challenges')));
const ChallengeRank = Loadable(lazy(() => import('views/challenges/ChallengeRank')));

// push notifications routing
const PushNotifications = Loadable(lazy(() => import('views/pushNotifications/PushNotifications')));

// rate app routing
const RateApp = Loadable(lazy(() => import('views/rateApp/RateApp')));

// Static pages control routing
const AboutUS = Loadable(lazy(() => import('views/staticPages/AboutUs')));
const PrivacyPolicy = Loadable(lazy(() => import('views/staticPages/PrivacyPolicy')));

// redeem request routing
const RedeemRequests = Loadable(lazy(() => import('views/redeemRequests/RedeemRequests')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: '',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'administration',
            children: [
                {
                    path: 'manage-admins',
                    element: <ManageAdmins />
                }
            ]
        },
        {
            path: 'users',
            children: [
                {
                    path: 'manage-users',
                    element: <ManageUsers />
                }
            ]
        },
        {
            path: 'feedbacks',
            children: [
                {
                    path: '',
                    element: <Feedback />
                }
            ]
        },
        {
            path: 'category',
            children: [
                {
                    path: 'manage-categories',
                    element: <Categories />
                },
                {
                    path: 'levels/:catId',
                    element: <Levels />
                },
                {
                    path: 'level/questions/:levelId',
                    element: <Questions />
                },
                {
                    path: 'random-questions',
                    element: <RandomQuestions />
                }
            ]
        },
        {
            path: 'competition',
            children: [
                {
                    path: 'manage-categories',
                    element: <CCategories />
                },
                {
                    path: 'levels/:catId',
                    element: <CLevels />
                },
                {
                    path: 'level/questions/:levelId',
                    element: <CQuestions />
                },
                {
                    path: 'winners',
                    element: <Winners />
                },
                {
                    path: 'random-questions',
                    element: <CRandomQuestions />
                }
            ]
        },
        {
            path: 'challenge',
            children: [
                {
                    path: 'manage-challenge',
                    element: <Challenges />
                },
                {
                    path: 'challenge-ranking',
                    element: <ChallengeRank />
                }
            ]
        },
        {
            path: 'word-search',
            element: <WordSearch />
        },
        {
            path: 'push-notifications',
            element: <PushNotifications />
        },
        {
            path: 'rate-apps',
            element: <RateApp />
        },
        {
            path: 'redeem-gold',
            element: <RedeemRequests />
        },
        {
            path: 'static-pages',
            children: [
                {
                    path: 'about-us',
                    element: <AboutUS />
                },
                {
                    path: 'privacy-policy',
                    element: <PrivacyPolicy />
                }
            ]
        }
    ]
};

export default MainRoutes;
