// Packages
import { useState } from 'react';

// MUI
import { makeStyles } from '@mui/styles';

// Projet Imports
import ReCaptcha from '@matt-block/react-recaptcha-v2';
import { RECAPTCHA_SITE_KEY } from '../../constants';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    },

    recaptcha: {
        transform: 'translateY(-50%)'
    }
}));

const Recaptcha = () => {
    const classes = useStyles();

    const [token, setToken] = useState(null);

    const handleVerificationSuccess = (captcha) => {
        console.log(`Successful, result is ${captcha}`);
        setToken(captcha);
        window.flutter_inappwebview.callHandler('onToken', token);
    };

    return (
        <div className={classes.container}>
            <div className={classes.recaptcha}>
                <ReCaptcha
                    siteKey={RECAPTCHA_SITE_KEY}
                    theme="light"
                    size="normal"
                    onSuccess={handleVerificationSuccess}
                    onError={() => console.log('Something went wrong, check your connection')}
                    onExpire={() => console.log('Verification has expired, re-verify.')}
                />
            </div>
        </div>
    );
};

export default Recaptcha;
