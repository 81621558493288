import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import { useRef } from 'react';
import { changeOldPassword } from '../../store/actions/admin';
import { useDispatch, useSelector } from 'react-redux';

const ChangePassword = () => {
    const customization = useSelector((state) => state.customization);
    const { changeThePassword } = customization;
    const dispatch = useDispatch();
    const oldPasswordRef = useRef();
    const newPasswordRef = useRef();

    const handleClose = () => {
        dispatch({ type: 'OPEN_CHANGE_PASSWORD', payload: { ...changeThePassword, open: false, id: '' } });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const oldPass = oldPasswordRef.current.value;
        const newPass = newPasswordRef.current.value;

        changeOldPassword(changeThePassword, { id: changeThePassword.id, oldPass, newPass }, dispatch);
    };
    return (
        <Dialog open={changeThePassword.open} onClose={handleClose}>
            <DialogTitle>
                Change Password
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                    onClick={handleClose}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent dividers>
                    <TextField
                        margin="normal"
                        variant="standard"
                        id="oldPassword"
                        label="Old Password"
                        type="password"
                        fullWidth
                        inputRef={oldPasswordRef}
                        inputProps={{ minLength: 8 }}
                        required
                    />

                    <TextField
                        margin="normal"
                        variant="standard"
                        id="newPassword"
                        label="New Password"
                        type="password"
                        fullWidth
                        inputRef={newPasswordRef}
                        inputProps={{ minLength: 8 }}
                        required
                    />
                </DialogContent>
                <DialogActions sx={{ px: '19px' }}>
                    <Button type="submit" variant="contained" endIcon={<Send />}>
                        Update
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ChangePassword;
