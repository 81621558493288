import { Button, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import { Close, NotificationsActive, Lock } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { changeStatus } from '../../store/actions/challenges/question';

// eslint-disable-next-line react/prop-types
const Check = () => {
    const customization = useSelector((state) => state.customization);
    const { checkChallenge } = customization;
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch({ type: 'OPEN_CHECK_CHALLENGE', payload: { ...checkChallenge, open: false } });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        changeStatus(checkChallenge, dispatch);
    };
    return (
        <Dialog open={checkChallenge.open} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>
                {checkChallenge.status ? (
                    <Typography fontSize={20} sx={{ mb: 4 }}>
                        Are You sure to turn OFF this challenge?
                    </Typography>
                ) : (
                    <Typography fontSize={20} sx={{ mb: 4 }}>
                        Are You sure to turn ON this challenge?
                    </Typography>
                )}
                {checkChallenge.status ? (
                    <Typography sx={{ mb: 2 }}>Users will not be able to enter the challenge !!!</Typography>
                ) : (
                    <Typography sx={{ mb: 2 }}>This action will sent notifications to all users !!!</Typography>
                )}
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                    onClick={handleClose}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <Typography sx={{ textAlign: 'center', mb: 2 }}>
                    {checkChallenge.status ? (
                        <Button type="submit" variant="contained" color="warning" endIcon={<Lock />}>
                            YES Close Challenge
                        </Button>
                    ) : (
                        <Button type="submit" variant="contained" color="warning" endIcon={<NotificationsActive />}>
                            YES Send Notifications
                        </Button>
                    )}
                </Typography>
            </form>
        </Dialog>
    );
};

export default Check;
