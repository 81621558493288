import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import { AnimatePresence } from 'framer-motion';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OptionItem from './OptionItem';
import { addQuestionOption } from '../../store/actions/challenges/question';

// eslint-disable-next-line react/prop-types
const EditOptions = () => {
    const customization = useSelector((state) => state.customization);
    const { questionOptions } = customization;
    const dispatch = useDispatch();
    const optionRef = useRef();

    const handleClose = () => {
        dispatch({ type: 'OPEN_EDIT_QUESTION_OPTIONS', payload: { ...questionOptions, open: false } });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const option = optionRef.current.value;
        addQuestionOption(questionOptions, { option, questionId: questionOptions.questionId }, dispatch);
    };
    return (
        <Dialog open={questionOptions.open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
                Question
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                    onClick={handleClose}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>{questionOptions.body}</DialogContentText>
                <AnimatePresence>
                    {questionOptions.options.length > 0 ? (
                        questionOptions.options.map((option) => <OptionItem key={option._id} option={option} />)
                    ) : (
                        <DialogContentText> No Options</DialogContentText>
                    )}
                </AnimatePresence>
            </DialogContent>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        margin="normal"
                        variant="standard"
                        id="name"
                        label="New Option"
                        type="text"
                        fullWidth
                        inputRef={optionRef}
                        required
                    />
                </DialogContent>
                <DialogActions sx={{ px: '19px' }}>
                    <Button type="submit" variant="contained" endIcon={<Send />}>
                        Add
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EditOptions;
