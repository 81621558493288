// project imports
import AboutUs from 'views/pages/AboutUs';
import PrivacyPolicy from 'views/pages/PrivacyPolicy';
import Recaptcha from 'views/pages/Recaptcha';

const Pages = {
    path: '/',
    children: [
        {
            path: '/about-us',
            element: <AboutUs />
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy />
        },
        {
            path: '/recaptcha',
            element: <Recaptcha />
        }
    ]
};

export default Pages;
