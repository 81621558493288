import { Button, Typography } from '@mui/material';
import { CloudDone, CloudOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line react/prop-types
const SwitchComponent = ({ status }) => {
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch({
            type: 'OPEN_CHECK_CHALLENGE',
            payload: {
                open: true,
                status: status
            }
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Typography sx={{ textAlign: 'center' }}>
                Turn Challenge ON or OFF and Notify Users
                {status ? (
                    <Button type="submit" sx={{ ml: 4 }} variant="contained" color="success" endIcon={<CloudDone />}>
                        ON
                    </Button>
                ) : (
                    <Button type="submit" sx={{ ml: 4 }} variant="contained" color="error" endIcon={<CloudOff />}>
                        OFF
                    </Button>
                )}
            </Typography>
        </form>
    );
};

export default SwitchComponent;
