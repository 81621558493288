// assets
import { IconFlag3, IconShield } from '@tabler/icons';

// constant
const icons = {
    IconFlag3,
    IconShield
};

// ==============================|| Feedbacks MENU ITEMS ||============================== //

const staticPages = {
    id: 'static-pages',
    title: 'Static Pages Control',
    type: 'group',
    children: [
        {
            id: 'about-us',
            title: 'About Us',
            type: 'item',
            url: '/static-pages/about-us',
            icon: icons.IconFlag3,
            breadcrumbs: false
        },
        {
            id: 'privacy-policy',
            title: 'Privacy Policy',
            type: 'item',
            url: '/static-pages/privacy-policy',
            icon: icons.IconShield,
            breadcrumbs: false
        }
    ]
};

export default staticPages;
