// assets
import { IconFileAlert, IconListCheck, IconGift, IconCalendarTime, IconBellRinging, IconStars, IconReplace } from '@tabler/icons';
import { IconExplicit } from '@tabler/icons-react';

// constant
const icons = {
    IconFileAlert,
    IconListCheck,
    IconGift,
    IconCalendarTime,
    IconBellRinging,
    IconStars,
    IconReplace,
    IconExplicit
};

// ==============================|| Mobile App MENU ITEMS ||============================== //

const mobileControl = {
    id: 'mobileControl',
    title: 'Mobile Control',
    caption: 'Application Config',
    type: 'group',
    children: [
        {
            id: 'challenges',
            title: 'Categories',
            type: 'collapse',
            icon: icons.IconListCheck,
            children: [
                {
                    id: 'challenge',
                    title: 'Categories',
                    type: 'item',
                    url: '/category/manage-categories'
                },
                {
                    id: 'random-questions-chall',
                    title: 'Random Questions',
                    type: 'item',
                    url: '/category/random-questions'
                }
            ]
        },
        {
            id: 'cometitions',
            title: 'Competitions',
            type: 'collapse',
            icon: icons.IconGift,
            children: [
                {
                    id: 'cometition',
                    title: 'Competitions',
                    type: 'item',
                    url: '/competition/manage-categories'
                },
                {
                    id: 'winners',
                    title: 'Winners',
                    type: 'item',
                    url: '/competition/winners'
                },
                {
                    id: 'random-questions-comp',
                    title: 'Random Questions',
                    type: 'item',
                    url: '/competition/random-questions'
                }
            ]
        },
        {
            id: 'time-challenges',
            title: 'Challenges',
            type: 'collapse',
            icon: icons.IconCalendarTime,
            children: [
                {
                    id: 'timechallenge',
                    title: 'Time Challenges',
                    type: 'item',
                    url: '/challenge/manage-challenge'
                },
                {
                    id: 'challenge-ranking',
                    title: 'Challenge Ranking',
                    type: 'item',
                    url: '/challenge/challenge-ranking'
                }
            ]
        },
        {
            id: 'redeem-gold',
            title: 'Redeem Requests',
            type: 'item',
            url: '/redeem-gold',
            icon: icons.IconReplace,
            breadcrumbs: false
        },
        {
            id: 'word-search',
            title: 'Word Search',
            type: 'item',
            url: '/word-search',
            icon: icons.IconExplicit,
            breadcrumbs: false
        },
        {
            id: 'push-notifications',
            title: 'Push Notifications',
            type: 'item',
            url: '/push-notifications',
            icon: icons.IconBellRinging,
            breadcrumbs: false
        },
        {
            id: 'rate-app',
            title: 'Rate Applications',
            type: 'item',
            url: '/rate-apps',
            icon: icons.IconStars,
            breadcrumbs: false
        }
    ]
};

export default mobileControl;
