import {
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionsOptions, editFieldsWordGame } from '../../store/actions/challenges/question';
import { useRef } from 'react';

// eslint-disable-next-line react/prop-types
const EditAnswer = () => {
    const customization = useSelector((state) => state.customization);
    const { questionData, questionDataOptions } = customization;
    const dispatch = useDispatch();
    const newAnswer = useRef();
    const newPrize = useRef();
    const newQuestion = useRef();

    useEffect(() => {
        if (questionData.open) {
            if (questionDataOptions.length === 0) getQuestionsOptions(questionData.questionId, dispatch);
        }
    }, [questionData.questionId, questionData.open, questionDataOptions.length, dispatch]);

    const handleClose = () => {
        dispatch({ type: 'OPEN_EDIT_QUESTION_ANSWER_OPTIONS', payload: [] });
        dispatch({ type: 'OPEN_EDIT_QUESTION_ANSWER', payload: { ...questionData, open: false } });
    };
    const handleSubmit = () => {
        editFieldsWordGame(
            questionData,
            { answer: newAnswer.current.value, question: newQuestion.current.value, prize: newPrize.current.value },
            dispatch
        );
    };
    return (
        <Dialog open={questionData.open} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>
                Question
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                    onClick={handleClose}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>{questionData.body}</DialogContentText>
                <DialogContentText>Current Answer: {questionData.currentAnswer}</DialogContentText>

                <TextField
                    margin="normal"
                    variant="standard"
                    id="name"
                    label="Type your Question"
                    type="text"
                    fullWidth
                    inputRef={newQuestion}
                />
                <TextField
                    margin="normal"
                    variant="standard"
                    id="name"
                    label="Type your Answer"
                    type="text"
                    fullWidth
                    inputRef={newAnswer}
                />
                <TextField
                    margin="normal"
                    variant="standard"
                    id="name"
                    label="Type your Prize"
                    type="number"
                    fullWidth
                    inputRef={newPrize}
                />
            </DialogContent>
            <DialogActions sx={{ px: '19px' }}>
                <Button onClick={handleSubmit} variant="contained" endIcon={<Send />}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditAnswer;
