import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { COOKIES } from 'constants';
import { BASE_URL } from 'constants';

const initialState = {
    data: {},
    loading: false,
    error: '',
    status: '',
    state: ''
};

export const DeleteAdminHandler = createAsyncThunk('DeleteAdminHandler/AdminData', async (arg) => {
    const api = `${BASE_URL}/remove-admin/${arg.id}`;
    try {
        const response = await axios.delete(api, { headers: { Authorization: localStorage.getItem('x_access_token') } });
        return {
            data: response.data,
            status: response.status
        };
    } catch (err) {
        return {
            message: err.response.message,
            status: err.response.status
        };
    }
});

const AdminData = createSlice({
    name: 'AdminData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(DeleteAdminHandler.fulfilled, (state, action) => {
            state.loading = true;
            if (action.payload.status === 200) {
                state.data = action.payload.data;
                state.loading = false;
                state.state = 'Success';
                state.status = action.payload.status;
            } else {
                state.data = {};
                state.loading = false;
                state.error = action.payload.message;
                state.state = 'Error';
                state.status = action.payload.status;
            }
        });
        builder.addCase(DeleteAdminHandler.pending, (state) => {
            state.loading = true;
            state.state = 'Pending..';
            state.status = '';
            state.error = '';
            state.data = {};
        });
        builder.addCase(DeleteAdminHandler.rejected, (state) => {
            state.loading = false;
            state.error = 'Server Error';
            state.state = 'Rejected';
            state.status = 500;
            state.data = {};
        });
    }
});

export default AdminData.reducer;
