// import { motion } from 'framer-motion';
// import { MdDelete, MdEdit } from 'react-icons/md';
// import { deleteTodo, updateTodo } from '../slices/todoSlice';
import { IconButton, Grid, Paper, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { ArrowRight, Delete } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { deleteQuestionOption } from '../../store/actions/challenges/question';

// eslint-disable-next-line react/prop-types
function OptionItem({ option }) {
    const customization = useSelector((state) => state.customization);
    const { questionOptions } = customization;
    const dispatch = useDispatch();

    // eslint-disable-next-line no-unused-vars
    const handleDelete = (event) => {
        // eslint-disable-next-line react/prop-types
        const optionId = option._id;
        deleteQuestionOption(questionOptions, optionId, dispatch);
    };

    return (
        <>
            <Grid container spacing={3} justify="center">
                <Grid item xs={10} sm={10} md={6} lg={6}>
                    <Paper>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <ArrowRight />
                                </ListItemAvatar>
                                {/* eslint-disable-next-line react/prop-types */}
                                <ListItemText primary={option.option} />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={(event) => handleDelete(event)} edge="end" color="error" aria-label="delete">
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default OptionItem;
