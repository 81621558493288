// assets
import { IconMessages } from '@tabler/icons';

// constant
const icons = {
    IconMessages
};

// ==============================|| Feedbacks MENU ITEMS ||============================== //

const feedback = {
    id: 'feedbacks',
    title: 'Feedbacks',
    type: 'group',
    children: [
        {
            id: 'feedback',
            title: 'Feedbacks',
            type: 'item',
            url: '/feedbacks',
            icon: icons.IconMessages,
            breadcrumbs: false
        }
    ]
};

export default feedback;
