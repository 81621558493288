import { Provider, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Notification from 'views/pages/authentication/auth-forms/Notification';
import Loading from 'views/administration/Loading';
import Profile from 'views/administration/Profile';
import ChangePassword from 'views/administration/ChangePassword';
import { store } from './redux/store';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        // <Provider store={store}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <Notification />
                <Profile />
                <ChangePassword />
                <Loading />
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
        // </Provider>
    );
};

export default App;
