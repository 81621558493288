// project imports
import fetchData from '../utils/fetchData';
import { ENDPOINT_BASE_URL } from '../../../constants';
import axios from 'axios';
import {
    UPDATE_QUESTIONS_CHALLENGE,
    OPEN_EDIT_QUESTION_OPTIONS,
    OPEN_EDIT_QUESTION_ANSWER,
    UPDATE_ADD_QUESTION,
    START_LOADING,
    END_LOADING,
    UPDATE_ALERT,
    OPEN_EDIT_QUESTION_ANSWER_OPTIONS,
    GET_CHALLENGES_STATUS,
    GET_CHALLENGES_GIFT,
    OPEN_CHECK_CHALLENGE,
    OPEN_CHALLENGES_GIFT,
    GET_CHALLENGES_MIN_POINTS,
    OPEN_DELETE_QUESTION,
    UPDATE_QUESTIONS_WORDSEARCH
} from '../../actions';

const url = ENDPOINT_BASE_URL;

export const getWordSearchQuestions = async (dispatch) => {
    dispatch({ type: START_LOADING });

    const result = await fetchData({ url: url + `/api/wordsearch/all/questions`, method: 'GET' }, dispatch);
    if (result) {
        dispatch({ type: GET_CHALLENGES_STATUS, payload: result.status });
        // // dispatch({ type: GET_CHALLENGES_GIFT, payload: result.gift });
        // // dispatch({ type: GET_CHALLENGES_MIN_POINTS, payload: result.min_points });
        dispatch({ type: UPDATE_QUESTIONS_CHALLENGE, payload: result });
    }
    dispatch({ type: END_LOADING });
};
export const getQuestions = async (dispatch) => {
    dispatch({ type: START_LOADING });

    const result = await fetchData({ url: url + `/api/challenge/question/all`, method: 'GET' }, dispatch);
    if (result) {
        dispatch({ type: GET_CHALLENGES_STATUS, payload: result.status });

        dispatch({ type: GET_CHALLENGES_GIFT, payload: result.gift });

        dispatch({ type: GET_CHALLENGES_MIN_POINTS, payload: result.min_points });

        dispatch({ type: UPDATE_QUESTIONS_CHALLENGE, payload: result.questions });
    }
    dispatch({ type: END_LOADING });
};

export const addQuestion = async (question, body, dispatch) => {
    dispatch({ type: START_LOADING });

    try {
        const result = await fetchData(
            {
                url: url + `/api/challenge/question/new`,
                method: 'POST',
                body: { body }
            },
            dispatch
        );
        if (result) {
            dispatch({ type: UPDATE_QUESTIONS_CHALLENGE, payload: result });
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Question added successfully'
                }
            });
            dispatch({
                type: UPDATE_ADD_QUESTION,
                payload: { ...question, open: false }
            });
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};
export const addWordSearchQuestion = async (question, body, dispatch) => {
    dispatch({ type: START_LOADING });

    try {
        const result = await axios.post(url + '/api/wordsearch/new/question', body);
        if (result) {
            dispatch({ type: UPDATE_QUESTIONS_WORDSEARCH, payload: result });
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Question added successfully'
                }
            });
            dispatch({
                type: UPDATE_ADD_QUESTION,
                payload: { ...question, open: false }
            });
            window.location.reload();
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};

export const updateQuestion = async (body, id, dispatch) => {
    const questionId = id;
    return await fetchData(
        { url: url + `/api/challenge/question/update-question/${questionId}`, method: 'PATCH', body: { body } },
        dispatch
    );
};

export const addQuestionOption = async (questionOptions, updatedFields, dispatch) => {
    dispatch({ type: START_LOADING });

    const { option, questionId } = updatedFields;
    try {
        const result = await fetchData(
            {
                url: url + `/api/challenge/question/new/option/${questionId}`,
                method: 'POST',
                body: { option }
            },
            dispatch
        );
        if (result) {
            dispatch({ type: OPEN_EDIT_QUESTION_OPTIONS, payload: { ...questionOptions, options: result.options } });
            getQuestions(dispatch);
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Option added successfully'
                }
            });
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};

export const deleteQuestionOption = async (questionOptions, optionId, dispatch) => {
    dispatch({ type: START_LOADING });
    const questionId = questionOptions.questionId;

    try {
        const result = await fetchData(
            {
                url: url + `/api/challenge/question/del/option/${questionId}`,
                method: 'DELETE',
                body: { optionId }
            },
            dispatch
        );
        if (result) {
            dispatch({ type: OPEN_EDIT_QUESTION_OPTIONS, payload: { ...questionOptions, options: result.options } });
            getQuestions(dispatch);
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Option deleted successfully'
                }
            });
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};

export const updateAnswer = async (questionData, updatedFields, dispatch) => {
    dispatch({ type: START_LOADING });

    const { answer, questionId } = updatedFields;
    try {
        const result = await fetchData(
            {
                url: url + `/api/challenge/question/update/answer/${questionId}`,
                method: 'PATCH',
                body: { answer }
            },
            dispatch
        );
        if (result) {
            dispatch({
                type: OPEN_EDIT_QUESTION_ANSWER,
                payload: { ...questionData, options: result.options, currentAnswer: result.answer }
            });
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Answer added successfully'
                }
            });
            getQuestions(dispatch);
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};

export const editFieldsWordGame = async (questionData, updatedFields, dispatch) => {
    dispatch({ type: START_LOADING });
    const result = await axios.put(`${url}/api/wordsearch/question/${questionData.questionId}`, updatedFields);
    if (result) {
        dispatch({
            type: OPEN_EDIT_QUESTION_ANSWER,
            payload: { ...questionData, options: result.options, currentAnswer: result.answer }
        });
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'success',
                message: 'Answer added successfully'
            }
        });
        getWordSearchQuestions(dispatch);
    }
    dispatch({ type: END_LOADING });
};

export const getQuestionsOptions = async (questionId, dispatch) => {
    dispatch({ type: START_LOADING });

    try {
        const result = await fetchData(
            {
                url: url + `/api/challenge/question/options/${questionId}`,
                method: 'GET'
            },
            dispatch
        );
        if (result) {
            dispatch({ type: OPEN_EDIT_QUESTION_ANSWER_OPTIONS, payload: result.options });
            dispatch({ type: END_LOADING });
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};

export const changeStatus = async (checkChallenge, dispatch) => {
    dispatch({ type: START_LOADING });

    try {
        const result = await fetchData(
            {
                url: url + `/api/challenge/toggle-status`,
                method: 'POST'
            },
            dispatch
        );

        if (result) {
            if (result.status) {
                dispatch({
                    type: UPDATE_ALERT,
                    payload: {
                        open: true,
                        severity: 'success',
                        message: 'Challenge Turned ON and Notifications Sent'
                    }
                });
            } else {
                dispatch({
                    type: UPDATE_ALERT,
                    payload: {
                        open: true,
                        severity: 'success',
                        message: 'Challenge Turned OFF'
                    }
                });
            }

            dispatch({ type: GET_CHALLENGES_STATUS, payload: result.status });

            dispatch({ type: OPEN_CHECK_CHALLENGE, payload: { ...checkChallenge, open: false } });
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};

export const updateChallengeGift = async (openChallengeGift, gift, min_points, dispatch) => {
    dispatch({ type: START_LOADING });

    try {
        const result = await fetchData(
            {
                url: url + `/api/challenge/update-gift`,
                method: 'PATCH',
                body: { gift, min_points }
            },
            dispatch
        );

        if (result) {
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Gift Updated'
                }
            });

            dispatch({ type: GET_CHALLENGES_GIFT, payload: result.status });

            dispatch({ type: OPEN_CHALLENGES_GIFT, payload: { ...openChallengeGift, open: false } });

            getQuestions(dispatch);
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};

export const deleteWordSearchQuestion = async (deleteQuestion, id, dispatch) => {
    dispatch({ type: START_LOADING });
    try {
        const result = await axios.delete(`${url}/api/wordsearch/delete/question/${id}`);
        if (result) {
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Question deleted'
                }
            });
            dispatch({
                type: OPEN_DELETE_QUESTION,
                payload: { ...deleteQuestion, open: false, id: '' }
            });

            getWordSearchQuestions(dispatch);
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};
export const deleteQuestions = async (deleteQuestion, id, dispatch) => {
    dispatch({ type: START_LOADING });

    try {
        const result = await fetchData(
            {
                url: url + `/api/challenge/question/delete/question/${id}`,
                method: 'DELETE',
                body
            },
            dispatch
        );
        if (result) {
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Question deleted'
                }
            });
            dispatch({
                type: OPEN_DELETE_QUESTION,
                payload: { ...deleteQuestion, open: false, id: '' }
            });

            getQuestions(dispatch);
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};
