import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    Box,
    Typography
} from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addWordSearchQuestion } from '../../store/actions/challenges/question';
import { useState } from 'react';

// eslint-disable-next-line react/prop-types
const AddQuestion = () => {
    const customization = useSelector((state) => state.customization);
    const { question } = customization;
    const dispatch = useDispatch();
    const questionRef = useRef();
    const answerRef = useRef();
    const [logo, setLogo] = useState();
    const prizeRef = useRef();

    const handleClose = () => {
        dispatch({ type: 'UPDATE_ADD_QUESTION', payload: { ...question, open: false } });
    };

    const handleFileChange = (event) => {
        setLogo(event.target.files[0]);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const body = new FormData();
        body.append('question', questionRef.current.value);
        body.append('image', logo);
        body.append('prize', prizeRef.current.value);
        body.append('answer', answerRef.current.value);
        addWordSearchQuestion(question, body, dispatch);
    };
    return (
        <Dialog open={question.open} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>
                Question
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                    onClick={handleClose}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent dividers>
                    <DialogContentText>You can add new Question:</DialogContentText>
                    {logo ? (
                        <Box
                            sx={{
                                width: '100%',
                                mx: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                overflow: 'hidden',
                                flexDirection: 'column',
                                gap: 2
                            }}
                        >
                            <img
                                width={'250px'}
                                height={'250px'}
                                src={URL.createObjectURL(logo)}
                                alt="uploaded"
                                style={{
                                    maxHeight: '100%',
                                    maxWidth: '100%'
                                    // borderRadius: 200
                                }}
                            />
                            <Button onClick={() => setLogo('')} color="error" variant="contained">
                                Remove
                            </Button>
                        </Box>
                    ) : (
                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={2}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    backgroundColor: 'transparent',
                                    padding: 5,
                                    mx: 'auto',
                                    width: '100%',
                                    height: '200px',
                                    border: `2px dashed white`,
                                    borderRadius: 1,
                                    '&:hover': {
                                        cursor: 'pointer',
                                        backgroundColor: '#e0e0e010'
                                    }
                                }}
                            >
                                <input
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    sx={{ display: 'none' }}
                                    id="upload-image"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="upload-image">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/* <CloudUploadIcon sx={{ fontSize: 48, marginBottom: 1 }} /> */}
                                        <Typography variant="h6">رفع صوره السؤال</Typography>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            انقر لرفع الصوره
                                        </Typography>
                                    </Box>
                                </label>
                            </Box>
                            {/* <Box color={'red'}>{logoError}</Box> */}
                        </Box>
                    )}
                    <TextField
                        margin="normal"
                        variant="standard"
                        id="name"
                        label="Type your Question"
                        type="text"
                        fullWidth
                        inputRef={questionRef}
                        // required
                    />
                    <TextField
                        margin="normal"
                        variant="standard"
                        id="name"
                        label="Type your Answer"
                        type="text"
                        fullWidth
                        inputRef={answerRef}
                        required
                    />
                    <TextField
                        margin="normal"
                        variant="standard"
                        id="name"
                        label="Type your Prize"
                        type="number"
                        fullWidth
                        inputRef={prizeRef}
                        required
                    />
                </DialogContent>
                <DialogActions sx={{ px: '19px' }}>
                    <Button type="submit" variant="contained" endIcon={<Send />}>
                        Add
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AddQuestion;
