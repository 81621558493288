import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Box, Typography, Button } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { grey } from '@mui/material/colors';
import { LibraryAddCheck } from '@mui/icons-material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { getQuestions, getWordSearchQuestions } from '../../store/actions/challenges/question';
import moment from 'moment';
import QuestionActions from './QuestionActions';
import AddQuestion from './AddQuestion';
import EditOptions from './EditOptions';
import EditOptionsIcon from './EditOptionsIcon';
import EditAnswerIcon from './EditAnswerIcon';
import EditAnswer from './EditAnswer';
import SwitchComponent from '../challenges/SwitchComponent';
import Check from './Check';
import OpenUpdateGift from './OpenUpdateGift';
import UpdateGift from './UpdateGift';
import OpenDeleteQuestion from './OpenDeleteQuestion';
import DeleteQuestion from './DeleteQuestion';
import Avatar from 'ui-component/extended/Avatar';

// ===============================|| UI Manage Questions ||=============================== //
function WordSearch() {
    const customization = useSelector((state) => state.customization);
    const { challengeQuestions } = customization;
    const dispatch = useDispatch();

    const [pageSize, setPageSize] = useState(20);
    const [rowId, setRowId] = useState(null);

    useEffect(() => {
        getWordSearchQuestions(dispatch);
    }, [dispatch]);

    const columns = useMemo(
        () => [
            {
                field: 'image',
                headerName: 'Image',
                width: 60,
                renderCell: (params) => <Avatar src={params.row.image} />,
                sortable: false,
                filterable: false
            },
            { field: 'question', width: 200, headerName: 'Question' },
            { field: 'answer', width: 200, headerName: 'Answer' },
            { field: 'prize', headerName: 'Prize', width: 200 },
            {
                field: 'editAnswer',
                headerName: 'Edit Answer',
                renderCell: (params) => <EditAnswerIcon {...{ params }} />
            },
            {
                field: 'createdAt',
                headerName: 'Created At',
                width: 100,
                renderCell: (params) => moment(params.row.createdAt).format('YYYY-MM-DD')
            },
            {
                field: 'delete',
                width: 200,
                headerName: 'Delete',
                renderCell: (params) => <OpenDeleteQuestion {...{ params, rowId, setRowId }} />
            }
        ],
        [rowId]
    );

    return (
        <MainCard title="">
            <AddQuestion />
            <EditOptions />
            <EditAnswer />
            <Check />
            <UpdateGift />
            <DeleteQuestion />
            <Box sx={{ height: 700, width: '100%', marginBottom: 10 }}>
                <Typography variant="h3" component="h3" sx={{ textAlign: 'center', mt: 3, mb: 3 }}>
                    Manage Word Search
                </Typography>
                <Typography variant="h3" component="h3" sx={{ textAlign: 'center', mt: 3, mb: 3 }}>
                    <Button
                        variant="contained"
                        startIcon={<LibraryAddCheck />}
                        onClick={() =>
                            dispatch({
                                type: 'UPDATE_ADD_QUESTION',
                                payload: { open: true }
                            })
                        }
                    >
                        Add New Question
                    </Button>
                </Typography>
                <div style={{ height: 500, overflow: 'auto' }}>
                    <DataGrid
                        autoHeight={false}
                        columns={columns}
                        rows={challengeQuestions}
                        getRowId={(row) => row._id}
                        rowsPerPageOptions={[20, 50, 100]}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageNumber) => setPageSize(newPageNumber)}
                        getRowSpacing={() => ({ top: 5, bottom: 5 })}
                        sx={{
                            [`& .${gridClasses.row}`]: {
                                bgcolor: grey[200]
                            }
                        }}
                        onCellEditCommit={(params) => setRowId(params.id)}
                    />
                </div>
            </Box>
        </MainCard>
    );
}

export default WordSearch;
