// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true,
    currentAdmin: null,
    admins: [],
    users: [],
    categories: [],
    levels: [],
    questions: [],
    feedbacks: [],
    winners: [],
    randomQuestions: [],
    alert: { open: false, severity: 'info', message: '' },
    loading: false,
    openAddAdmin: false,
    profile: { open: false, file: null, photoURL: '' },
    category: { open: false, file: null, imageURL: '' },
    categoryImage: { open: false, file: null, imageURL: '', catId: '' },
    level: { open: false, file: null, imageURL: '' },
    levelImage: { open: false, file: null, imageURL: '', levelId: '' },
    question: { open: false, levelId: '' },
    questionOptions: { open: false, options: [], questionId: '', body: '' },
    questionDataOptions: [],
    questionData: { open: false, options: [], questionId: '', body: '', currentAnswer: '' },
    randomQuestion: { open: false, levelId: '' },
    randomQuestionOptions: { open: false, options: [], questionId: '', body: '' },
    randomQuestionData: { open: false, options: [], questionId: '', body: '', currentAnswer: '' },
    randomQuestionDataOptions: [],
    deleteWinner: { open: false, winnerId: '' },
    challengeQuestions: [],
    checkChallenge: { open: false, status: null },
    challengesStatus: null,
    challengesGift: '',
    challengesMinPoints: 0,
    notifications: [],
    notify: { open: false },
    openChallengeGift: { open: false, gift: '' },
    challengeUsers: [],
    reteApp: { googleLink: '', appleLink: '' },
    staticPages: { aboutUsPage: '', privacyPolicyPage: '' },
    redeemRequests: [],
    deleteRequest: { open: false, userId: '' },
    deleteQuestion: { open: false, id: '' },
    deleteUser: { open: false, id: '' },
    changeThePassword: { open: false, id: '' },
    forgotThePassword: { open: false },
    incrementPoints: { open: false, id: '' },
    decrementPoints: { open: false, id: '' }
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        case actionTypes.UPDATE_ADMIN:
            return { ...state, currentAdmin: action.payload };

        case actionTypes.UPDATE_ADMINS:
            return { ...state, admins: action.payload };

        case actionTypes.UPDATE_ALERT:
            return { ...state, alert: action.payload };

        case actionTypes.START_LOADING:
            return { ...state, loading: true };

        case actionTypes.END_LOADING:
            return { ...state, loading: false };

        case actionTypes.OPEN_ADD_ADMIN:
            return { ...state, openAddAdmin: true };

        case actionTypes.CLOSE_ADD_ADMIN:
            return { ...state, openAddAdmin: false };

        case actionTypes.UPDATE_PROFILE:
            return { ...state, profile: action.payload };

        case actionTypes.UPDATE_USERS:
            return { ...state, users: action.payload };

        case actionTypes.UPDATE_CATEGORIES:
            return { ...state, categories: action.payload };

        case actionTypes.UPDATE_ADD_CATEGORY:
            return { ...state, category: action.payload };

        case actionTypes.UPDATE_CATEGORY_IMAGE:
            return { ...state, categoryImage: action.payload };

        case actionTypes.UPDATE_LEVELS:
            return { ...state, levels: action.payload };

        case actionTypes.UPDATE_ADD_LEVEL:
            return { ...state, level: action.payload };

        case actionTypes.UPDATE_LEVEL_IMAGE:
            return { ...state, levelImage: action.payload };

        case actionTypes.UPDATE_QUESTIONS:
            return { ...state, questions: action.payload };

        case actionTypes.UPDATE_ADD_QUESTION:
            return { ...state, question: action.payload };

        case actionTypes.UPDATE_WORD_QUESTION:
            return { ...state, question: action.payload };

        case actionTypes.UPDATE_QUESTIONS_WORDSEARCH:
            return { ...state, questions: action.payload };

        case actionTypes.OPEN_EDIT_QUESTION_OPTIONS:
            return { ...state, questionOptions: action.payload };

        case actionTypes.OPEN_EDIT_QUESTION_ANSWER:
            return { ...state, questionData: action.payload };

        case actionTypes.OPEN_EDIT_QUESTION_ANSWER_OPTIONS:
            return { ...state, questionDataOptions: action.payload };

        case actionTypes.UPDATE_RANDOM_QUESTIONS:
            return { ...state, randomQuestions: action.payload };

        case actionTypes.UPDATE_ADD_RANDOM_QUESTION:
            return { ...state, randomQuestion: action.payload };

        case actionTypes.OPEN_EDIT_RANDOM_QUESTION_OPTIONS:
            return { ...state, randomQuestionOptions: action.payload };

        case actionTypes.OPEN_EDIT_RANDOM_QUESTION_ANSWER:
            return { ...state, randomQuestionData: action.payload };

        case actionTypes.OPEN_EDIT_RANDOM_QUESTION_ANSWER_OPTIONS:
            return { ...state, randomQuestionDataOptions: action.payload };

        case actionTypes.UPDATE_FEEDBACKS:
            return { ...state, feedbacks: action.payload };

        case actionTypes.UPDATE_WINNERS:
            return { ...state, winners: action.payload };

        case actionTypes.OPEN_DELETE_WINNER:
            return { ...state, deleteWinner: action.payload };

        case actionTypes.UPDATE_QUESTIONS_CHALLENGE:
            return { ...state, challengeQuestions: action.payload };

        case actionTypes.OPEN_CHECK_CHALLENGE:
            return { ...state, checkChallenge: action.payload };

        case actionTypes.GET_CHALLENGES_STATUS:
            return { ...state, challengesStatus: action.payload };

        case actionTypes.GET_CHALLENGES_GIFT:
            return { ...state, challengesGift: action.payload };

        case actionTypes.GET_CHALLENGES_MIN_POINTS:
            return { ...state, challengesMinPoints: action.payload };

        case actionTypes.GET_ALL_NOTIFICATIONS:
            return { ...state, notifications: action.payload };

        case actionTypes.OPEN_SEND_NOTIFICATION:
            return { ...state, notify: action.payload };

        case actionTypes.OPEN_CHALLENGES_GIFT:
            return { ...state, openChallengeGift: action.payload };

        case actionTypes.GET_CHALLENGE_RANK:
            return { ...state, challengeUsers: action.payload };

        case actionTypes.RATE_APP_STORE_LINKS:
            return { ...state, rateApp: action.payload };

        case actionTypes.ABOUT_US_STATIC_PAGE:
            return { ...state, staticPages: action.payload };

        case actionTypes.GET_REDEEM_REQUESTS:
            return { ...state, redeemRequests: action.payload };

        case actionTypes.OPEN_DELETE_REDEEM_REQUEST:
            return { ...state, deleteRequest: action.payload };

        case actionTypes.OPEN_DELETE_QUESTION:
            return { ...state, deleteQuestion: action.payload };

        case actionTypes.OPEN_CHANGE_PASSWORD:
            return { ...state, changeThePassword: action.payload };

        case actionTypes.OPEN_FORGOT_PASSWORD:
            return { ...state, forgotThePassword: action.payload };

        case actionTypes.OPEN_DELETE_USER:
            return { ...state, deleteUser: action.payload };

        case actionTypes.OPEN_INCREMENT_USER_POINTS:
            return { ...state, incrementPoints: action.payload };

        case actionTypes.OPEN_DECREMENT_USER_POINTS:
            return { ...state, decrementPoints: action.payload };
        default:
            return state;
    }
};

export default customizationReducer;
