// project imports
import fetchData from './utils/fetchData';
import fetchDataWithImage from './utils/fetchDataWithImage';
import { ENDPOINT_BASE_URL } from '../../constants';
import {
    UPDATE_ADMIN,
    UPDATE_ADMINS,
    OPEN_CHANGE_PASSWORD,
    CLOSE_ADD_ADMIN,
    UPDATE_ALERT,
    END_LOADING,
    START_LOADING,
    UPDATE_PROFILE
} from '../actions';
import FormData from 'form-data';

const url = ENDPOINT_BASE_URL;

export const register = async (admin, dispatch) => {
    dispatch({ type: START_LOADING });

    const result = await fetchData({ url: url + '/api/dashboard/admin/register', body: admin }, dispatch);
    if (result) {
        dispatch({ type: CLOSE_ADD_ADMIN });
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'success',
                message: 'Account has been created successfully'
            }
        });
    }

    dispatch({ type: END_LOADING });
    window.location.reload();
};

export const login = async (admin, dispatch) => {
    const result = await fetchData({ url: url + '/api/dashboard/admin/login', body: admin }, dispatch);
    if (result) {
        dispatch({ type: UPDATE_ADMIN, payload: result });
        localStorage.setItem('currentAdmin', JSON.stringify(result));
        localStorage.setItem('x_access_token', JSON.stringify(result.token));
        window.location.reload();
    }
};

export const updateProfile = async (currentAdmin, updatedFields, dispatch) => {
    dispatch({ type: START_LOADING });

    const { name, file } = updatedFields;

    var formdata = new FormData();
    formdata.append('name', name);
    formdata.append('image', file);

    try {
        const result = await fetchDataWithImage(
            {
                url: url + `/api/dashboard/admin/update-profile?adminId=${currentAdmin._id}`,
                method: 'PATCH',
                body: formdata
            },
            dispatch
        );
        if (result) {
            dispatch({ type: UPDATE_ADMIN, payload: { ...currentAdmin, ...result } });
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Your profile has been updated successfully'
                }
            });
            dispatch({
                type: UPDATE_PROFILE,
                payload: { open: false, file: null, photoURL: result.photoURL }
            });
            localStorage.setItem('currentAdmin', JSON.stringify(result));
            localStorage.setItem('x_access_token', JSON.stringify(result.token));
            window.location.reload();
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
        console.log(error);
    }

    dispatch({ type: END_LOADING });
};

export const getAdmins = async (dispatch) => {
    const result = await fetchData({ url: url + '/api/dashboard/admin/all', method: 'GET' }, dispatch);
    if (result) {
        dispatch({ type: UPDATE_ADMINS, payload: result });
    }
};

export const updateStatus = (updatedFields, adminId, dispatch) => {
    return fetchData(
        {
            url: `${url}/api/dashboard/admin/update-status/${adminId}`,
            method: 'PATCH',
            body: updatedFields
        },
        dispatch
    );
};

export const changeOldPassword = async (changeThePassword, { id, oldPass, newPass }, dispatch) => {
    dispatch({ type: START_LOADING });

    try {
        const result = await fetchData(
            {
                url: url + `/api/auth/change-password/${id}`,
                method: 'PATCH',
                body: { oldPass, newPass }
            },
            dispatch
        );

        if (result) {
            dispatch({ type: OPEN_CHANGE_PASSWORD, payload: { ...changeThePassword, open: false, id: '' } });
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Password Changed'
                }
            });
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
        console.log(error);
    }

    dispatch({ type: END_LOADING });
};

export const forgotOldPassword = async (forgotThePassword, email, dispatch) => {
    dispatch({ type: START_LOADING });

    try {
        const result = await fetchData(
            {
                url: url + `/api/auth/forgot-password/`,
                method: 'POST',
                body: { email }
            },
            dispatch
        );

        if (result) {
            dispatch({ type: OPEN_FORGOT_PASSWORD, payload: { ...forgotThePassword, open: false } });
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Check your Email'
                }
            });
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};
