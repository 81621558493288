import axios from 'axios';
import { UPDATE_ALERT } from '../../actions';

const fetchDataWithImage = async ({ url, body }, dispatch) => {
    try {
        const response = await axios.patch(url, body);
        const data = response.data;
        if (!data.success) {
            if (response.status === 401 || response.status === 501) dispatch({ type: 'UPDATE_ADMIN', payload: null });
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'error',
                    message: data.message
                }
            });
        }
        return data.result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export default fetchDataWithImage;
