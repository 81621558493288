import { Box, CircularProgress, Fab } from '@mui/material';
import { useEffect, useState } from 'react';
import { Check, Save } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { updateQuestion } from '../../store/actions/challenges/question';

// eslint-disable-next-line react/prop-types
const QuestionActions = ({ params, rowId, setRowId }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);

        // eslint-disable-next-line react/prop-types
        const { id } = params;
        // eslint-disable-next-line react/prop-types
        const { body } = params.row;
        // eslint-disable-next-line react/prop-types
        const result = await updateQuestion(body, id, dispatch);

        if (result) {
            setSuccess(true);
            setRowId(null);
        }
        setLoading(false);
    };

    useEffect(() => {
        // eslint-disable-next-line react/prop-types
        if (rowId === params.id && success) setSuccess(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowId]);

    return (
        <Box
            sx={{
                m: 1,
                position: 'relative'
            }}
        >
            {success ? (
                <Fab
                    color="primary"
                    sx={{
                        width: 40,
                        height: 40,
                        bgcolor: green[500],
                        '&:hover': { bgcolor: green[700] }
                    }}
                >
                    <Check />
                </Fab>
            ) : (
                <Fab
                    color="primary"
                    sx={{
                        width: 40,
                        height: 40
                    }}
                    // eslint-disable-next-line react/prop-types
                    disabled={params.id !== rowId || loading}
                    onClick={handleSubmit}
                >
                    <Save />
                </Fab>
            )}
            {loading && (
                <CircularProgress
                    size={52}
                    sx={{
                        color: green[500],
                        position: 'absolute',
                        top: -6,
                        left: -6,
                        zIndex: 1
                    }}
                />
            )}
        </Box>
    );
};

export default QuestionActions;
